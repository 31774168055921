.cont-home {
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.logo {
     width: 200px;
     height: 200px;
     left: 0;
}
.cont-header-princ {
    background-color: #2bbbad;
    
}

.cont-texto {
    font-size: 30px;
    margin-left: 8px;
    margin-top: 30px;    
}
.t1{
    margin: 0;
    color: #FFFFFF;
}
.cont-sociales {
    display: flex;
   align-items: center;
}
.navbart {
    width:500px;
    
}
.navbar{
    left:50%;
    margin-left:-100px;
    width:200px;
}
.nav-link {
    font-size: large !important;
    color: #FFFFFF !important; 
    margin-right: 50px;
}
.nav-link:hover {
    color: #D8D8D8 !important; 
}
a{
    color: #FFFFFF !important;
    text-decoration: none !important;     
}
a:hover {
    color: #D8D8D8 !important; 
}
.dropdown-item{
    color: black !important;
}
.dropdown-item:hover{
    background-color: #2bbbad !important;
    color: #FFFFFF !important;
}



  
  /* Style the sidenav links and the dropdown button */
  .sidenav a, .dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width:280px;
    text-align: left;
    cursor: pointer;
    outline: none;
  }
  
  /* On mouse-over */
  .sidenav a:hover, .dropdown-btn:hover {
    background-color: #2bbbad !important;
    color: #FFFFFF !important;
  }
  
  
  /* Add an active class to the active dropdown button */
  .active {
    background-color: green;
    color: white;
  }
  
  /* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
  .dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
  }
  
  .dropdown-containeractivo {
    background-color: #262626;
    padding-left: 8px;
  }
  /* Optional: Style the caret down icon */
  .fa-caret-down {
    float: right;
    padding-right: 8px;
  }
  
  .planilla {
    color: red;
  }
  .planilla1 {
    color: black;
    font-weight: bold;
    font-size: 18px;
  }
  .titulo-planilla {
    color: blue;
    font-size: 20px;
    text-align: right;
  }